import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'

export const useFeaturedPositions = () => {
  const { allWpJobListing } = useStaticQuery(
    graphql`
      query {
        allWpJobListing(
          filter: {acfJobListing: {isFeaturedPosition: {in: true}}}
          sort: {order: ASC, fields: acfJobListing___featuredPosition}
        ) {
          nodes {
            id
            uri
            title
            slug
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                title
                altText
              }
            }
            acfJobListing {
              featuredExcerpt
              featuredPosition
              isFeaturedPosition
              city
              state
            }
          }
        }
      }
      
    `
  )
  return allWpJobListing.nodes;
}
