import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'

export const useCurrentOpenings = () => {
  const { allWpJobListing } = useStaticQuery(
    graphql`
      query {
        allWpJobListing {
          nodes {
            id
            uri
            title
            content
            acfJobListing {
              baseSalaryMaximum
              baseSalaryMinimum
              benefits
              baseSalaryUnit
              formRecipient
              fieldGroupName
              estimatedSalaryUnit
              estimatedSalaryMinimum
              estimatedSalaryMaximum
              employmentType
              displayLocation
              city
              qualifications
              requirements
              responsibilities
              resumeRequired
              state
              subtitle
              youtubeVideo
              zip
              positionType {
                ... on WpPositionType {
                  id
                  name
                }
              }
              positionMarket {
                id
                name
              }
            }
          }
         }

       }
    `
  )
  return allWpJobListing.nodes;
}
